import React from 'react';
import { Helmet } from 'react-helmet';

const MetaCategory = ({ serverData }) => {
  console.log('wwwserverData', serverData);
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" defer={false}></meta>
        <title>{`${serverData?.keyword}- 優惠推薦-最快1小時送達| 家速配`}</title>
        <h1>{`${serverData?.keyword}`}</h1>
        <meta
          property="description"
          content={`家速配提供多元的${serverData?.keyword}選擇，在家速配獲得最多的${serverData?.keyword}優惠推薦，新客輸入折扣碼「NEW」享99元免運！無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！}，方便買快速配。家速配是家樂福快速配送服務，可累積也可折抵家樂福會員紅利點數！預約下單可指定時間，宅配、到店取貨都方便！標示24h門市不打烊，半夜也能送到家。無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta
          property="og:title"
          content={`${serverData?.keyword}- 優惠推薦-最快1小時送達| 家速配`}
        />
        <meta
          property="og:description"
          content={`家速配提供多元的${serverData?.keyword}選擇，在家速配獲得最多的${serverData?.keyword}優惠推薦，新客輸入折扣碼「NEW」享99元免運！無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！}，方便買快速配。家速配是家樂福快速配送服務，可累積也可折抵家樂福會員紅利點數！預約下單可指定時間，宅配、到店取貨都方便！標示24h門市不打烊，半夜也能送到家。無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta property="og:url" content={`${serverData?.crrentUrl}`} />
        {/* logo圖檔放在static裏面 部署的時候會搬到public */}
        <meta
          property="og:image"
          content={`https://${serverData?.host}/useimages/logo.png`}
        />
      </Helmet>
    </>
  );
};

export default MetaCategory;
