import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import _, { forEach } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isDesktop, isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { navigate } from 'gatsby';
import { SEO_API_DOMAIN } from 'utils/constants/seo.js';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Breadcrumb from 'components/UI/Breadcrumb';
import Pagination from 'components/UI/Pagination';
import Footer from 'components/UI/Footer';
import CardProduct from 'components/UI/Card/AdditionCardProduct';
import MetaSearch from 'components/Seo/Meta/MetaSearch';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { getDispatchData } from 'utils/_dispatch.js';
import { isAuthenticated } from 'utils/_auth.js';
import { setSelectItem } from 'utils/_gtag.js';

import IconArrow from 'images/arrow.svg';

const mixinResultList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 28px;

  @media (max-width: ${md}) {
    gap: 8px;
    margin-bottom: 0;
  }
`;

const mixinCardProduct = css`
  flex: 0 0 calc((100% - 4 * 12px) / 5);
  min-height: 291px;

  @media (max-width: ${md}) {
    flex: 0 0 calc((100% - 2 * 8px) / 3);
    min-height: 184px;
  }
`;

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
  }

  .skeleton-search-wrapper {
    ${mixinResultList};

    .skeleton-search {
      ${mixinCardProduct};
    }
  }
`;

const StyledHeader = styled(Header)`
  .address-wrapper,
  .switch-delivery-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  .title {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const StyledPagination = styled(Pagination)`
  margin-bottom: 10px;
`;

const StyledCardProduct = styled(CardProduct)`
  ${mixinCardProduct};
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    margin-bottom: 5px;
    align-items: center;
  }
`;

const SearchText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #333333;

  @media (max-width: ${md}) {
    font-size: 12px;
  }

  > span {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: ${md}) {
      font-size: 18px;
    }
  }
`;

const ResultList = styled.div`
  ${mixinResultList};
`;

const SwitchList = styled.div`
  display: flex;
  gap: 0 12px;
`;

const SwitchBtn = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #3b3516;
  cursor: pointer;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  @media (max-width: ${md}) {
    font-size: 14px;
  }

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 500;
      border-bottom: 2px solid #5fd2da;
    `}
`;

const Section = styled.div`
  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 9px 14px;
  }
`;

let currentPage = 1;
let total = 0;
let totalPage = 0;
const perPage = 25;
let arrProducts = [];

const SearchResult = ({ location }) => {
  const api = useAPI();
  const resultList = useRef();
  const loading = useLoading();
  const dispatchData = getDispatchData();
  const storeId = _.get(dispatchData, 'storeId', '');
  const PAGE_CACHE_KEY = 'searchResultPage';
  const searchParams = new URLSearchParams(location.search);
  const keyword = '水';

  const additionItems = useSelector((state) => getters.getAdditionItems(state));
  const [additionItemsData, setAdditionItemsData] = useState({});
  const localCacheData = useSelector((state) => getters.getLocalCache(state));
  const keepaliveData = useSelector((state) => getters.getKeepalive(state));
  const [domLoaded, setDomLoaded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [searchWord, setSearchWord] = useState('');
  const [results, setResults] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [showNoData, setShowNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    per_page: 25,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const paginationConfig = {
    perPage: 25, //一頁幾筆
  };

  const fetchAdditionItemList = () => {
    setIsLoading(true);
    const params = {
      shippingModel: 1,
    };
    api
      .getAddtionItems(params)
      .then((res) => {
        setIsLoading(false);
        store.dispatch(actions.setAdditionItems(res.rows));
        if (!isMobile) {
          divideData(res.rows);
        }
      })
      .catch((error) => {});
  };

  const renderResultList = () => {
    return (
      <ResultList>
        {isMobile &&
          additionItems?.map((product, index) => {
            return (
              <StyledCardProduct
                key={`StyledCardProduct-${index}`}
                type={'addition'}
                goToPage={`/product?pid=${product.productId}`}
                onChangeCount={(count, productId) => {
                  // handleChangeCount(count, productId);
                }}
                productData={product}
                onGo2Detail={(id) => {}}
              />
            );
          })}
        {isDesktop &&
          additionItemsData[currentPage]?.map((product, index) => {
            return (
              <StyledCardProduct
                key={`StyledCardProduct-${index}`}
                type={'addition'}
                goToPage={`/product?pid=${product.productId}`}
                onChangeCount={(count, productId) => {
                  // handleChangeCount(count, productId);
                }}
                productData={product}
                onGo2Detail={(id) => {}}
              />
            );
          })}
      </ResultList>
    );
  };

  const divideData = (arrData) => {
    const perPage = paginationConfig.perPage;
    const pagTotal = Math.ceil(arrData.length / perPage);

    const data = {};
    for (let i = 0; i < pagTotal; i++) {
      const start = i * perPage;
      const end = start + perPage;
      data[i + 1] = arrData.slice(start, end);
    }

    setAdditionItemsData(data);
    setPageInfo({
      page: 1,
      per_page: perPage,
      total: arrData.length,
    });
  };

  /* const divideData = (arrData) => {
    let pagTotal = arrData.length / paginationConfig.perPage;
    if (arrData.length % paginationConfig.perPage !== 0) {
      pagTotal = Math.floor(pagTotal) + 1;
    }

    let count = 0;
    let data = {};
    let arrProduct = [];
    let indexRow = 1;
    arrData.forEach((item, index) => {
      if (count < paginationConfig.perPage) {
        arrProduct.push(item);
        count++;
      }
      if (count >= paginationConfig.perPage) {
        data[indexRow] = arrProduct;
        arrProduct = [];
        console.log('data', data);
        indexRow++;
        count = 0;
      }
    });

    if (count < paginationConfig.perPage) {
      count = 0;
      data[indexRow] = arrProduct;
      arrProduct = [];
    }
    setAdditionItemsData(data);
    setPageInfo({
      page: 1,
      per_page: paginationConfig.perPage,
      total: arrData.length,
    });

    console.log('final data', data);
  }; */

  // 桌機版
  useEffect(() => {
    fetchAdditionItemList();
  }, []);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader title="搜尋結果" onRefresh={() => {}} />
          </HeaderWrapper>
          <Section>
            {additionItems && additionItems.length >= 0 && (
              <TitleBar>
                <SearchText>
                  <span>加價購商品</span>
                </SearchText>
              </TitleBar>
            )}

            {isDesktop && isLoading && (
              <Skeleton
                containerClassName="skeleton-search-wrapper"
                className="skeleton-search"
                count={30}
                inline
              />
            )}
            {isDesktop && additionItems.length >= 0 && !isLoading && (
              <>
                {renderResultList()}
                <StyledPagination
                  total={pageInfo.total}
                  pageSize={Number(pageInfo.per_page)}
                  current={Number(pageInfo.page)}
                  onPageChange={(page) => {
                    //切換頁面
                    setCurrentPage(page);
                    setPageInfo({
                      page: page,
                      per_page: paginationConfig.perPage,
                      total: additionItems.length,
                    });
                    console.log('page', page);
                  }}
                />
              </>
            )}

            {isMobile && isLoading && (
              <Skeleton
                containerClassName="skeleton-search-wrapper"
                className="skeleton-search"
                count={30}
                inline
              />
            )}
            {isMobile && additionItems.length >= 0 && !isLoading && (
              <InfiniteScroll
                ref={resultList}
                dataLength={additionItems.length}
                next={() => {}}
                hasMore={hasMore}
                style={{ height: '100%', overflow: 'unset' }}
                loader={<h6>資料載入中...請稍候</h6>}
              >
                {renderResultList()}
              </InfiniteScroll>
            )}
          </Section>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default SearchResult;
