export const SEO_DOMAIN = 'https://c4fast.carrefour.com.tw';
export const SEO_API_DOMAIN = 'https://34.36.14.78.nip.io/api/';
export const SEO_API_HOMEPAGE_ONE_NOTOKEN =
  SEO_API_DOMAIN + 'v1/qc-site/Api-homepage1NoToken';
export const SEO_API_HOMEPAGE_TWO_NOTOKEN =
  SEO_API_DOMAIN + 'v1/qc-site/Api-homepage2NoToken';
export const SEO_API_CATEGORIES_NOTOKEN =
  SEO_API_DOMAIN + 'v1/qc-site/Api-getCategoriesV2NoToken';
export const SEO_API_CATEGORY_PRODUCT_NOTOKEN =
  SEO_API_DOMAIN + 'v1/qc-site/Api-getCategoryProductV2NoToken';
export const SEO_API_PRODUCT_DETAIL =
  SEO_API_DOMAIN + 'v1/qc-site/Api-getProductDetailNoToken';
